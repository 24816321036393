@import "./variables.scss";

.bronnenbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  background-image: url("../assets/imgs/bg/Bg_Mobile_.svg");
  background-position: center top;
  background-repeat: repeat-y;
  background-size: 150%;
  position: relative;

  min-height: 100vh;
  overflow: hidden;

  &__logo {
    max-width: 250px;
  }
  &__title {
    font-size: 37px;
    margin-top: 0px;
  }

  &__filter-btn {
  }
  &__filter-menu {
    background-image: url("../assets/imgs/bg/BG_Filter.svg");
    background-size: auto;
    background-position: center right;
    background-repeat: no-repeat;
    background-color: #000000;

    min-height: 95vh;
    width: 90vw;
    max-width: 600px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-left: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    position: fixed;
    overflow-y: scroll;
    top: 0;
    right: 0;
    box-shadow: 0px 20px 10px 10px #00000085;

    &--open {
      transition: all ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
      scroll-behavior: smooth;
    }
    &--closed {
      transition: all ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
      left: 100vw;
    }
    label {
      font-size: 18px;
      font-family: "Montserrat";
      max-width: 60%;
      &:first-of-type {
        margin-bottom: 30px;
      }
      input {
        margin: 10px 0px;
      }
      img {
        width: 20px;
        height: 20px;
        margin: 0px 10px;
      }
    }
    button {
      margin: 50px 0px;
      min-width: 200px;
      margin-right: auto;
    }
  }

  &__sources {
    margin: 40px;
    width: 90vw;
    // max-width: 1300px;
    display: grid;
    gap: 20px;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 430px));
    // grid-template-rows: masonry;
    grid-template-rows: repeat(auto-fit, 1fr);

    @media screen and (max-width: $breakpoint-s) {
      max-width: 450px;
    }

    .source {
      background: #272727 0% 0% no-repeat padding-box;
      border-radius: 10px 10px 10px 10px;
      box-shadow: 10px 10px 20px #000000;
      border: 1px solid #272727;
      max-width: 430px;
      display: flex;
      flex-direction: column;
      text-decoration: none;

      &__header {
        padding: 20px 30px 10px 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-title {
          margin: 0px;
          padding-right: 5%;
        }
        a {
          width: 30px;
          height: 30px;
          background-size: contain;
          background-position: center;
        }
      }
      &__body {
        padding: 0px 30px 20px 30px;
        background: #272727 0% 0% no-repeat padding-box;
        border-radius: 0px 0px 0px 0px;

        &-themes {
          display: flex;
          gap: 5px;
          margin: 0px;
          flex-wrap: wrap;

          li {
            background: #4a4949 0% 0% no-repeat padding-box;
            border-radius: 4px;
            padding: 3px 5px;
            font-weight: 300;
          }
        }
      }
      &__footer {
        padding: 20px 30px 20px 30px;
        background-color: #121212;
        margin-top: auto !important;
        border-radius: 0px 0px 10px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-skills {
          display: flex;
          gap: 15px;
          margin: 0px;
          flex-wrap: wrap;

          li {
            font-weight: 300;
            width: 35px;
            height: 35px;
          }
        }
        .number {
          color: white;
          background: #272727 0% 0% no-repeat padding-box;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "all-round-gothic", sans-serif !important;
          font-weight: 400;
          font-size: 20px;
        }
      }
      &:hover {
        img {
        }
      }
    }
  }
}
