.pngbron {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
}

.videobron {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100vw;
  padding: 10%;
  box-sizing: border-box;
  background-image: url("../assets/imgs/bg/Bg_video_transp.png");
  background-repeat: no-repeat;
  background-size: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-position: top;
  background-color: white;
  color: #272727 !important;

  div:first-of-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: $breakpoint-m) {
      flex-direction: column-reverse;
    }
    h1 {
      color: #272727 !important;
    }
    img {
      max-height: 100px;

      @media screen and (max-width: $breakpoint-m) {
        max-height: 70px;
        max-width: 80%;
        align-self: flex-end;
      }
    }
  }

  p {
    align-self: flex-start;
    color: #272727 !important;
  }

  div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
    iframe {
      width: 560px;
      height: 315px;
      margin: 30px auto 10px auto;
      padding: 0px;
      border-radius: 10px;

      @media screen and (max-width: 600px) {
        width: 90vw;
        height: 50.625vw;
      }
    }
    p.bronvermelding {
      color: #4a49498a !important;
      font-size: 9px;
      margin: 0px 0px;
      width: 70%;
      max-width: 400px;

      span {
        font-style: italic;
      }
    }
  }
}
