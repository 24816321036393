@import "./scss/variables.scss";
@import "./scss/components.scss";
@import "./scss/pages.scss";
@import "./scss/bronnenbox.scss";
@import "./scss/normalize.css";
@import "./assets/fonts/oetang.css";

body {
  background-color: #121212;
  color: white;
  box-sizing: border-box;
}

i {
  font-family: "oetang";
}

h1,
h2,
h3 {
  font-family: "all-round-gothic", sans-serif !important;
  color: white;
}

h3 {
  font-size: 24px;
}

p,
ul,
ol {
  font-family: "Montserrat";
  color: white;
}

ul,
ol {
  list-style: none;
  margin-left: 0;
  padding: 0;
  li {
  }
}

.checkbox__container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ .checkbox__checkmark {
    // background-color: #121212;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkbox__checkmark {
      //   background-color: #2196f3;
    }
    &:checked ~ .checkbox__checkmark:after {
      display: block;
    }
  }
}

.checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  height: 25px;
  width: 25px;
  background-color: #3b3b3b;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.checkbox__container .checkbox__checkmark:after {
  left: 7px;
  top: 0px;
  width: 9px;
  height: 17px;
  border: 1px solid #d82c22;
  border-radius: 2px;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.btn--red {
  background-color: #d82c22;
  font-family: "all-round-gothic", sans-serif !important;
  text-transform: uppercase;
  min-width: 150px;
  height: 50px;
  min-height: 50px;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  border-style: none;
  position: relative;
  top: 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;
  padding: 10px 30px;
  gap: 20px;

  &:hover,
  &:active {
    position: relative;
    top: -1px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }
}
.closeIcon {
  width: 30px;
  position: absolute;
  top: 30px;
  right: 70px;
  cursor: pointer;
}
