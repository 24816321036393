#home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-height: 400px;
  width: 100vw;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box !important;
  background: url("../assets/imgs/bg/bg-shapes_onepager.svg");
  background-repeat: no-repeat;
  background-size: 160vw;
  background-position: -30vw 120vh;
  background-color: #272727;

  > * {
    box-sizing: border-box;
  }
  p {
    font-family: "Montserrat", sans-serif;
    color: #9da1b2;
    font-size: 18px;
    line-height: 26px;
    max-width: 100%;
    strong {
      color: white;
      font-weight: 600;
    }
    a {
      color: #d82c22;
      text-decoration: underline;
      font-weight: 600;
      &:hover {
        color: #4a4949;
        i {
          background-color: #4a4949;
          transition: all 0.3s ease-in-out;
        }
        transition: all 0.3s ease-in-out;
      }
    }
  }
  h2 {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 20px;
    span {
      color: #d82c22;
    }
    @media screen and (max-width: $breakpoint-m) {
      font-size: 32px;
      line-height: 38px;
      max-width: 95%;
    }
  }
  h3 {
    font-size: 32px;
    line-height: 36px;
  }

  .btn--red {
    font-weight: 600;
    text-transform: none;
    font-size: 22px;

    @media screen and (max-width: $breakpoint-m) {
      i {
        font-size: 22px;
      }
      font-size: 18px;
    }

    &:first-letter {
      text-transform: capitalize;
    }
    i {
    }
  }

  @media screen and (min-width: $breakpoint-l) {
    .navigation {
      display: block;
    }
    .mobile-top {
      display: none;
    }
  }

  @media screen and (max-width: $breakpoint-l) {
    .navigation {
      display: none !important;
    }
    .mobile-top {
      display: block;
    }
  }
  .mobile-top {
    img {
      width: 40vw;
    }
  }

  main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(39, 39, 39, 1) 10%
    );
  }

  section {
    padding: 60px 5vw;
    width: 100vw;
    box-sizing: border-box;
    overflow: hidden;
    max-width: 1400px;
  }
  section.mediascanners,
  section.lespakket,
  section.digitale-tools {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    & > div {
      width: 50%;
    }
    p {
      margin-right: 0px;
    }

    @media screen and (max-width: $breakpoint-s) {
    }
  }

  section.hero {
    padding: 60px 0vw;
    width: 100vw !important;
    max-width: 100vw;
    padding-top: 0px;
    // background-image: url("../assets/imgs/HEADER_IMAGE.png");

    background: rgb(39, 39, 39);
    background: -moz-linear-gradient(
        0deg,
        rgba(39, 39, 39, 1) 0%,
        rgba(39, 39, 39, 0) 21%
      ),
      url("../assets/imgs/HEADER_IMAGE.png");
    background: -webkit-linear-gradient(
        0deg,
        rgba(39, 39, 39, 1) 0%,
        rgba(39, 39, 39, 0) 21%
      ),
      url("../assets/imgs/HEADER_IMAGE.png");
    background: linear-gradient(
        0deg,
        rgba(39, 39, 39, 1) 0%,
        rgba(39, 39, 39, 0) 21%
      ),
      url("../assets/imgs/HEADER_IMAGE.png");
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#272727",endColorstr="#272727",GradientType=1);
    background-position: top left;
    background-size: cover;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    overflow: visible;
    justify-content: right;

    .hero__top {
      width: 100%;
      .navigation {
        display: flex;
        flex-direction: row;
        padding: 30px 5%;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        flex-wrap: nowrap;
        align-items: stretch;
        @media screen and (max-width: $breakpoint-l) {
          justify-content: center;
        }
        &__left {
          img {
            width: 25vw;
            max-width: 350px;
          }
        }
        &__right {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 35px;
          align-items: center;

          .navigation__link {
            color: white;
            font-weight: 600;
            text-decoration: none;
            vertical-align: middle;
            box-sizing: border-box;
            padding: 30px 0px;
            transition: all ease-in-out 0, 3s;

            &:hover {
              color: #d82c22;
              transition: all ease-in-out 0, 3s;
            }
          }
        }
      }
    }
    .hero__bottom {
      padding: 0px 5vw 0px 5vw;
      width: 40%;
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      ul {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;
        @media screen and (max-width: $breakpoint-s) {
        }
        li {
          background-color: #4a4949;
          padding: 5px 10px;
          border-radius: 4px;
        }
      }
    }
  }
  .mediascanners {
    &__right {
      display: flex;
      justify-content: right;
    }
    img {
      max-width: 70vw;
    }
  }

  .lespakket {
    display: flex;
    flex-direction: column-reverse !important;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;

    &__left {
      max-width: 90vw;
    }

    &__right {
      z-index: 1;
      background-color: black;
      padding: 50px;
      border-radius: 10px;
      box-sizing: border-box;
      justify-self: flex-end;
    }

    @media screen and (min-width: $breakpoint-m) {
      flex-direction: row !important;
      flex-wrap: nowrap !important;
      align-items: space-between;
      width: 100%;
      gap: 0;
      position: relative;
      &__left {
        position: absolute;
        top: 0px;
      }
      &__right {
        margin: 0 0 0 auto;
        align-self: flex-start;
        overflow: visible;
      }
    }
  }
  .digitale-tools {
    display: flex;
    flex-direction: row;

    &__left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 60px;
      .btn--red  {
        &:first-letter {
          text-transform: capitalize;
        }
      }
      > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        margin-top: 20px;
        a:nth-of-type(2) {
          i {
            font-size: 28px;
          }
        }
      }
    }
    &__right {
      display: flex;
      align-items: right;
      flex-direction: row;
      position: relative;
      img {
        position: relative !important;
        top: 0;
        left: 0;
        max-width: 90vw;
        &:first-of-type {
          top: 0;
          left: 0;
        }
      }
      @media screen and (max-width: $breakpoint-m) {
        img {
        }
      }
    }
  }

  .downloads {
    background-color: #4a4949;
    padding: 60px 5vw;
    width: 90vw;
    box-sizing: border-box;
    overflow: hidden;
    max-width: 1200px;
    border-radius: 10px;
    > div {
      background-color: #e3e3e3;
      border-radius: 10px;
      padding: 30px 5%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        color: #272727 !important;
        margin-top: 0px;
      }
      > div {
        display: flex;
        flex-direction: row;
        gap: 10px;
        a:nth-of-type(2) {
          i {
            font-size: 28px;
          }
        }
      }
    }
    h2 {
      margin-top: 0px;
    }
    h3 {
      margin-top: 60px;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;
      margin: 30px 0px;

      li {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;

        a {
          color: #d82c22;
          font-size: 22px;
          font-weight: 600;
          width: 40%;

          i {
            color: white;
            background-color: #d82c22;
            border-radius: 50%;
            padding: 8px;
            font-size: 14px;
            margin-right: 15px;
          }
        }
        span {
          color: #4a4949;
        }
        transition: all 0.3s ease-in-out;

        @media screen and (max-width: $breakpoint-m) {
          flex-direction: column;
          a {
            font-size: 18px;
            width: 100%;
          }
          span {
            width: 100%;
            text-align: right;
          }
        }

        &:hover {
          a {
            color: #4a4949;
            i {
              background-color: #4a4949;
              transition: all 0.3s ease-in-out;
            }
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }
  footer {
    background-color: black;
    width: 100vw;
    padding: 10%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .footer__tekst {
      max-width: 1000px;
      text-align: center;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 50px;
      font-family: "DM Sans", sans-serif !important;

      p a {
        text-decoration: underline;
        font-weight: bold;
        transition: 0.3s all ease-in-out;

        &:hover {
          color: #4a4949;
          i {
            background-color: #4a4949;
            transition: all 0.3s ease-in-out;
          }
          transition: all 0.3s ease-in-out;
        }
      }
    }
    .footer__logos {
      display: flex;
      width: 100%;
      justify-content: center;

      a:first-of-type {
        margin-right: 30px;
      }
      img {
        height: 75px;
      }
    }
  }
  @media screen and (max-width: $breakpoint-l) {
    section {
      flex-direction: column !important;
      background-size: contain !important;
      padding: 50px 2vw;
      .mobile-top {
        display: flex;
        justify-content: center;
        img {
          margin-top: -5%;
        }
      }
      & > div {
        width: 80vw !important;
        margin: 0 auto;
      }
      img {
        margin: 0 auto;
      }
    }

    .downloads > div > div {
      flex-direction: column;
      align-items: flex-start;
    }
    .downloads {
      > div:last-of-type {
        flex-wrap: wrap;
        a {
          width: 100%;
        }
      }
      span {
        display: block;
      }
    }
  }
}
